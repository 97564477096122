import React, { useState } from 'react'
import { Link } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Footer = () => {
    const [ email, setEmail ] = useState()
    const [ error, setError ] = useState()
    const [ subscribed, setSubscribed ] = useState(false)
    const [ msg, setMsg ] = useState('')

    const subscribeEmail = (e) => {
        setEmail(e)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if(email) {
            addToMailchimp(email)
                .then(data => {
                    if(data.msg) {
                        setSubscribed(true)
                        const split = data.msg.split('<')
                        setMsg(split[0])
                    }
                })
                .catch(err => console.log('eerr',err))
        }
        else {
            setError('Please enter your email first and then click subscribe.')
        }
        
    }

    return (
        <div className="bg-mainColor">
            <div className=" font-light max-w-screen-xl w-4/5 md:w-full m-auto">
                <div className="flex flex-wra text-white p-4 sm:py-8 ">
                    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3">
                        <div className="text-left p-2 flex flex-col items-start md:col-span-2 lg:col-span-1">
                            <div className="text-xl pb-3 text-left font-semibold">Subscribe to our newsletter</div>
                            <form className="sm:flex items-center justify-center" onSubmit={(e)=>handleSubmit(e)}>
                                <input onChange={(e)=>subscribeEmail(e.target.value)} className="bg-gray-100 mr-2 m-2 appearance-none rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-100" id="inline-full-name" type="email" required placeholder="Enter your email"/>
                                <button  className="bg-yellowColor font-bold text-mainColor m-2 transition-shadow py-2 px-4 rounded" type="submit">Subscribe</button>
                            </form>
                            {error ? <p className="mt-2 font-bold xs:text-md md:text-lg">{error}</p> : null}
                            {subscribed ? <p className="text-white text-center mt-2 font-bold xs:text-md md:text-lg">{msg}</p> : null}
                        </div>
                        <div className="text-center p-2 flex flex-col md:items-center justify-center">
                            <div className="text-left mb-2 md:mb-0 ">
                                <ul className="p-0 leading-relaxed">
                                    <li><Link className="text-white no-underline" to={'/features'}>Features</Link></li>
                                    <li><Link className="text-white no-underline" to={'/pricing'}>Pricing</Link></li>
                                    <li><Link className="text-white no-underline" to={'/#download'}>Download</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className=" text-left mb-2 md:mb-0 p-2 flex flex-col md:items-center leading-relaxed justify-center">
                            <ul className="p-0">

                                <li><Link className="text-white no-underline" to={'/blog'}>Blog</Link></li>
                                <li><Link className="text-white no-underline" to={'/company'}>About Us</Link></li>
                                <li><Link className="text-white no-underline" to= {'/contact'}>Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                </div> 
                <div className=" w-4/5 m-auto text-white pl-6 text-center sm:items-center sm:flex sm:justify-between text-sm font-light">
                    <p className="bottom text-white"> Copyright &copy; Ricta Technologies SRL 2022. All rights reserved</p>
                    <div className="flex divide-x border-white divide-white ">
                        <Link className="text-white no-underline px-3" to={'/privacy-policy'}>Privacy</Link>
                        <Link className="text-white no-underline px-3" to={'/cookie-policy'}>Terms and conditions of use</Link>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Footer